import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { Query } from '../../../types/dato';
import { PageProps } from 'gatsby';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { applySectionMargin } from '../../utils/utils';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="WhyPayFitPage"
      dataName={'datoCmsWhyPayfitPage'}
    />
  );
};

export const query = graphql`
  query DatoWhyPayfitPage($locale: String, $id: String) {
    datoCmsWhyPayfitPage(locale: $locale, id: { eq: $id }) {
      ...WhyPayFitPage
    }
  }
`;

const WhyPayFitPage = ({ data, pageContext }: PageProps<Query>) => {
  const doc: any = data.datoCmsWhyPayfitPage;
  const _data = morphism(schemaBaseTemplate, { ...doc, pageContext });

  if (!doc) return null;
  return (
    <Layout pageContext={pageContext} data={doc} templateName="WhyPayFitPage">
      <Hero
        {...morphism(schemaHero, _data?.hero)}
        className={applySectionMargin(0, [])}
      />
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default WhyPayFitPage;
